import React from 'react'
import {Link} from 'gatsby'
import styles from './article-preview.module.css'

export default ({ app }) => (
    <li key={app.slug}>
        <div className={styles.preview}>
            <h3 className={styles.previewTitle}>
            <Link to={`/apps/${app.slug}`}>{app.title}</Link>
            </h3>
            <small>{app.description}</small>
        </div>
    </li>
)