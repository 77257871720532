import React from "react";
import { graphql } from 'gatsby'
import get from "lodash/get";
import Helmet from "react-helmet";
import AppPreview from "../components/app-preview";
import Layout from '../components/layout-template'

class AppIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const apps = get(this, "props.data.allContentfulApp.edges");

    return (
      <Layout location={this.props.location} >
        <div>
          <Helmet title={`Apps | ${siteTitle}`} />
          <div className="wrapper">
            <h1 className="section-headline">Our apps</h1>
            <ul className="article-list">
              {apps.map(({ node }) => {
                return (
                  <AppPreview app={node} />
                );
              })}
            </ul>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AppIndex;

export const pageQuery = graphql`
query AppsIndexQuery {
  allContentfulApp(sort: {fields: [title], order: DESC}) {
    edges {
      node {
        title
        slug
      }
    }
  }
}
`;
